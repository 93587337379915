
import { defineComponent } from "vue-demi";
import Mailing from "@/components/mailing/List.vue";

export default defineComponent({
  name: "MailingListView",
  components: {
    Mailing,
  },
});
